import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

// Previews
import { withPreview } from 'gatsby-source-prismic';

const Page = styled.div`
  padding: 0 15px;

  max-width: calc((100vh + 28.12%) - 200px);
  width: 100%;
  width: min(100%, calc((100vh + 28.12%) - 200px));
`;

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};

  width: 100%;
  height: 100%;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Playbutton = styled.div`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;

  border-color: transparent transparent transparent #fff;
  transition: 100ms opacity ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 22px 0 22px 40px;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 50;

  &:hover {
    opacity: 0.8;
  }
`;

const Poster = styled.div`
  opacity: ${(props) => props.opacity};
  transition: 1000ms opacity ease;

  & img {
    z-index: 40;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${video}&width=${width}&height=${height}&quality=1080`
  );

  let data = await response.json();
  return data;
}

const VideoPage = ({ data }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [vimeoThumbnail, setVimeoThumbnail] = useState(null);

  useEffect(() => {
    const vimeoThumbnail = getVimeoThumbnail(
      data.prismicVideoPage.data.video.embed_url,
      1920,
      1080
    );
    vimeoThumbnail.then((result) => {
      setVimeoThumbnail(result.thumbnail_url);
    });
  }, []);

  return (
    <Page>
      <IframeContainer
        aspectRatio={
          (data.prismicVideoPage.data.video.height /
            data.prismicVideoPage.data.video.width) *
          100
        }
      >
        <Poster opacity={isVideoPlaying ? 0 : 1}>
          <Playbutton onClick={() => setIsVideoPlaying(true)} />
          {vimeoThumbnail !== null && (
            <img src={vimeoThumbnail} alt="Thumbnail" />
          )}
        </Poster>
        <ReactPlayer
          url={data.prismicVideoPage.data.video.embed_url}
          width="100%"
          height="100%"
          playing={isVideoPlaying}
          loop={true}
          controls
          playsline
        />
      </IframeContainer>
    </Page>
  );
};
export default withPreview(VideoPage);

export const query = graphql`
  query VideoPageQuery {
    prismicVideoPage {
      data {
        video {
          embed_url
          width
          height
        }
      }
    }
  }
`;
